import React from 'react'

import Layout from '../components/layout'
import { Link } from 'gatsby'

import styles from './case.module.scss'
import { getMenuList } from '../api/common'

const CasePage = ({location}) => {
  const list = getMenuList('case')
  list.unshift({url: '/case', title: '全部'})

  return <Layout>
    <div className={`container ${styles.container}`}>
      <div className={styles.left}>
        {list.map(
          l => <Link
            key={l.url}
            to={l.url}
            className={`${styles.item} ${(location.pathname +
              location.hash === l.url)
              ? styles.active
              : ''}`}>
            {l.title}
          </Link>)}
      </div>
      <div className={styles.right}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
          () => <div className={`${styles.item}`}>
            <img
              src="http://pic1.win4000.com/wallpaper/0/57ba5b70305e0.jpg"
              alt="" />
            <div>企业形象升级</div>
          </div>)}
      </div>
    </div>
  </Layout>
}

export default CasePage
